/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import Helmet from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"
import { audioObject, musicRecording, musicGroup, musicComposition, graphWrapper } from "schema-comsat"
import { convertBreadcrumb } from "./schema-translate"

function SchemaMusicRecording({
  breadcrumbs,
  recordingOf,
  datePublished,
  title,
  pathname,
}) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            canonicalUrl
            author
          }
        }
      }
    `
  )

  const rootUrl = site.siteMetadata.canonicalUrl
  const contentUrl = rootUrl + pathname + "/listen.mp3"

  if (recordingOf) {
    recordingOf = musicComposition({id: rootUrl + recordingOf})
  }

  const schema = graphWrapper([
    musicRecording({
      "id": rootUrl + pathname,
      byArtist: musicGroup({
        name: site.siteMetadata.title,
        id: rootUrl,
      }),
      datePublished,
      mainEntityOfPage: rootUrl + pathname,
      name: title,
      audio: audioObject({ contentUrl }),
      recordingOf
    }),
    convertBreadcrumb(rootUrl, breadcrumbs)
  ])


  return (
    <Helmet>
      <meta property="article:published_time" content={datePublished} />
      <meta property="og:type" content="music.song" />
      <meta property="og:audio:type" content="audio/mpeg" />
      <meta property="og:audio" content={contentUrl} />
      <meta name="twitter:card" content="summary" />
      <script type="application/ld+json">{JSON.stringify(schema)}</script>
    </Helmet>
  )
}

SchemaMusicRecording.propTypes = {
  pathname: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  datePublished: PropTypes.string.isRequired,
  recordingOf: PropTypes.string,
}

export default SchemaMusicRecording
